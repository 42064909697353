import { Heading, Text } from '@chakra-ui/react';

import { SettingsAccountType } from 'src/Settings/SettingsAccountType';
import { SettingsChannels } from 'src/Settings/SettingsChannels';
import { SettingsExperts } from 'src/Settings/SettingsExperts';
import { SettingsFeatures } from 'src/Settings/SettingsFeatures';
import { SettingsIntegrations } from 'src/Settings/SettingsIntegrations';
import { SettingsNotion } from 'src/Settings/SettingsNotion';

export function SettingsPage() {
  return (
    <>
      <SettingsAccountType />
      <Heading size="md" mt={10}>
        Knowledge Sources
      </Heading>
      <SettingsIntegrations />
      <Text mt={2} fontWeight={500} color="gray.500" fontSize="xs">
        Include search results from your favorite tools
      </Text>
      <SettingsExperts />
      <SettingsChannels />
      <SettingsFeatures />
      <SettingsNotion />
    </>
  );
}
