import { useEffect, useState } from 'react';
import { BsFillLightningChargeFill } from 'react-icons/bs';
import { HiDotsHorizontal } from 'react-icons/hi';

import {
  Flex,
  Heading,
  PopoverCloseButton,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import {
  Button,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  VStack,
} from '@chakra-ui/react';
import {
  RagieConnectEndpoint,
  RagieConnection,
  RagieDisconnectEndpoint,
  RagieListEndpoint,
} from '@core/types/types.endpoint.ragie';
import { serviceIcon } from '@core/util/util.serviceIcon';
import { serviceName } from '@core/util/util.serviceName';

import { SettingsModalIntegrations } from 'src/Settings/components/SettingsModalIntegrations';
import { useApi } from 'src/shared/hooks/shared.hook.useApi';
import { useFlag } from 'src/shared/hooks/shared.hook.useFlag';
import { useSubmitter } from 'src/shared/hooks/useSubmitter';
import { callApi } from 'src/shared/util/util.callApi';

const INTEGRATIONS = [
  'confluence',
  'dropbox',
  'freshdesk',
  'google_drive',
  'notion',
];

export function SettingsIntegrations() {
  const hasIntegrations = useFlag('integrations');
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data, refetch } = useApi<RagieListEndpoint>(
    '/ragie/list',
    {},
    hasIntegrations,
  );
  const [sc, setSC] = useState<RagieConnection | null>(null);

  useEffect(() => {
    if (data?.connections.find((c) => c.syncing)) {
      const tId = setTimeout(() => refetch(), 5000);
      return () => clearTimeout(tId);
    }
  }, [hasIntegrations]);

  const submitter = useSubmitter(async (sourceType: string) => {
    if (!hasIntegrations) {
      onOpen();
      return;
    }

    const [, res] = await callApi<RagieConnectEndpoint>('/ragie/connect', {
      sourceType,
      local: window.location.host === 'localhost:3000' ? '1' : undefined,
      redirectTo: window.location.pathname,
    });
    if (res?.url) {
      window.location.href = res.url;
    }
  });

  const disconnect = useSubmitter(async (connectionId: string) => {
    await callApi<RagieDisconnectEndpoint>('/ragie/disconnect', {
      connectionId,
    });
    await refetch();
  });

  return (
    <>
      <SettingsModalIntegrations
        isOpen={isOpen}
        onClose={onClose}
        feature="integrations"
      />

      {!!data?.connections.length &&
        data?.connections.map((c, i) => (
          <Flex
            mt={2}
            key={i}
            display="flex"
            alignItems="center"
            position="relative"
            _hover={{
              '& > button': {
                opacity: 1,
              },
            }}
          >
            <Image src={serviceIcon('', c.type)} w={6} h={6} mr={2} />
            {c.name}

            <Text fontSize="xs" ml={2} color="gray.500">
              ({c.syncing ? 'Syncing...' : serviceName(c.type)})
            </Text>

            <Popover
              placement="right"
              closeOnBlur
              closeOnEsc
              onClose={() => setSC(null)}
              isOpen={sc === c}
            >
              <PopoverTrigger>
                <IconButton
                  aria-label="Options"
                  icon={<HiDotsHorizontal />}
                  variant="ghost"
                  size="sm"
                  ml={2}
                  opacity={0}
                  transition="opacity 0.2s"
                  onClick={() => setSC(c)}
                />
              </PopoverTrigger>
              <PopoverContent width="200px">
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverBody>
                  <VStack align="stretch" spacing={2}>
                    <Heading size="sm" fontWeight="medium">
                      {serviceName(c.type)}
                    </Heading>
                    <Text fontSize="xs">{c.name}</Text>
                    <Text fontSize="xs" color="gray.500">
                      Last synced:{' '}
                      {c.lastSyncedAt
                        ? new Date(c.lastSyncedAt).toLocaleString()
                        : 'Never'}
                    </Text>
                    <Button
                      size="sm"
                      colorScheme="red"
                      variant="ghost"
                      onClick={() => disconnect.submit(c.id)}
                      isLoading={disconnect.isSubmitting}
                    >
                      Disconnect
                    </Button>
                  </VStack>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Flex>
        ))}
      <Menu>
        <MenuButton
          as={Button}
          colorScheme="action"
          leftIcon={<BsFillLightningChargeFill />}
          size="sm"
          mt={2}
          isLoading={submitter.isSubmitting}
        >
          Connect
        </MenuButton>
        <MenuList>
          {INTEGRATIONS.map((integration) => (
            <MenuItem
              key={integration}
              onClick={() => submitter.submit(integration)}
              fontSize="sm"
              fontWeight={500}
            >
              <Image src={serviceIcon('', integration)} w={6} h={6} mr={3} />
              {serviceName(integration)}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </>
  );
}
