import { useRef, useState } from 'react';
import { LuPlus } from 'react-icons/lu';

import {
  Button,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { QuestionAddEndpoint } from '@core/types/types.endpoint.question';

import { AnswerEditor } from 'src/shared/components/AnswerEditor';
import { DuplicateQuestionButton } from 'src/shared/components/DuplicateQuestionButton/DuplicateQuestionButton';
import { EditorButtons } from 'src/shared/components/Editor/Buttons/EditorButtons';
import { useSharedEditor } from 'src/shared/components/Editor/useSharedEditor';
import { TitleEditor } from 'src/shared/components/TitleEditor';
import { useIsEditor } from 'src/shared/hooks/shared.hook.useIsEditor';
import { useSubmitter } from 'src/shared/hooks/useSubmitter';
import { callApi } from 'src/shared/util/util.callApi';

export function AppTopbarAddQuestion() {
  const isEditor = useIsEditor();
  const toast = useToast();
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [title, setTitle] = useState('');
  const { editor } = useSharedEditor({
    editable: true,
  });

  const adder = useSubmitter(async () => {
    const trimmed = title.trim();
    const newAnswerJson = JSON.stringify(editor?.getJSON());

    if (!editor || !trimmed) return;

    const [, res] = await callApi<QuestionAddEndpoint>('/question/add', {
      title: trimmed,
      answerBase64: btoa(newAnswerJson),
    });

    if (res) {
      toast({
        title: 'Question added',
        status: 'success',
      });
      setTitle('');
      editor.commands.clearContent();
    }
  });

  if (!isEditor) return null;

  return (
    <>
      <Tooltip label="Add a new question" placement="top-end">
        <IconButton
          isRound
          colorScheme="action"
          onClick={onOpen}
          aria-label="Add a new question"
          icon={<Icon as={LuPlus} h={5} w={5} color="white" />}
        />
      </Tooltip>
      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            borderBottom="1px solid rgba(3, 52, 38, 0.1)"
            background="rgba(3, 52, 38, 0.03)"
            color="rgba(3, 52, 38, 1)"
            letterSpacing="-0.01em"
            fontWeight={500}
            p={4}
            height="56px"
            fontSize="16px"
            position="relative"
            lineHeight={1.5}
          >
            Add new question
            <ModalCloseButton top="50%" transform="translateY(-50%)" />
          </ModalHeader>
          <ModalBody pb={30}>
            <TitleEditor
              title={title}
              setTitle={setTitle}
              pb={4}
              pt={3}
              ref={inputRef}
              fontSize="15px"
              fontWeight={500}
              letterSpacing="-0.01em"
              lineHeight={1.5}
              color="rgba(3, 52, 38, 1)"
            />
            <AnswerEditor mt={3} editor={editor} className="" />
          </ModalBody>
          <ModalFooter>
            {editor && <EditorButtons editor={editor} />}
            <DuplicateQuestionButton title={title} inputRef={inputRef} />
            <Button
              ml={2}
              isLoading={adder.isSubmitting}
              colorScheme="action"
              onClick={adder.submit}
              isDisabled={!title.trim() || !editor?.getText().trim()}
            >
              Save & verify
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
