import { Link } from 'react-router-dom';

import { Button, Center, Flex, Heading, Spinner, Text } from '@chakra-ui/react';

import { useDirectoryRefresher } from 'src/shared/hooks/shared.hook.useDirectoryRefresher';
import { useDirectoryTeam } from 'src/shared/hooks/useDirectory';

export function AppOnboardingSuccess() {
  const team = useDirectoryTeam();

  const isPro = team.stripe_subscription_status === 'active';
  const isImporting = team.capture_queue?.length > 0;

  const needUpdate = !!team.capture_is_running;
  useDirectoryRefresher(
    {
      team: true,
    },
    needUpdate,
    5000,
  );

  return (
    <Center display="flex" flexDirection="column">
      <Heading size="sm">
        {isImporting
          ? '☕️ Converting your channel history.'
          : `That's it! 👌 You can start documenting in Slack.`}
      </Heading>
      <Text textAlign="center" mt={8} color="gray.600">
        You are on the {isPro ? 'pro' : 'free'} plan and can save{' '}
        {isPro ? 'unlimited' : 'up to 100'} answers.
      </Text>
      {needUpdate && <Spinner />}
      {!needUpdate && (
        <Flex mt={8}>
          <Button
            as="a"
            size="sm"
            colorScheme="action"
            href={`slack://open?team=${team.id}`}
            target="_blank"
          >
            Go to Slack
          </Button>
          <Button size="sm" colorScheme="action" ml={4} as={Link} to="/">
            Open Question Base
          </Button>
        </Flex>
      )}
    </Center>
  );
}
