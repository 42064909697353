import { useState } from 'react';

import {
  Box,
  Button,
  Flex,
  OrderedList,
  Radio,
  RadioGroup,
  Select,
  Text,
} from '@chakra-ui/react';
import { OnboardingPreconfigureEndpoint } from '@core/types/types.endpoint.onboarding';

import { ListEl } from 'src/App/App.ListEl';
import { LANGUAGES } from 'src/Settings/const/Settings.const.LANGUAGES';
import { useDirectoryUpdater } from 'src/shared/hooks/shared.hook.useDirectoryUpdater';
import { useSubmitter } from 'src/shared/hooks/useSubmitter';
import { callApi } from 'src/shared/util/util.callApi';

export function AppOnboardingPreconfigure() {
  const updater = useDirectoryUpdater();
  const [importType, setImportType] = useState('');
  const [language, setLanguage] = useState('English');

  const submit = useSubmitter(async () => {
    const [, res] = await callApi<OnboardingPreconfigureEndpoint>(
      '/directory/preconfigure',
      {
        language,
        use_existing_data: importType === 'existing',
      },
    );

    updater(res);
  });

  return (
    <>
      <OrderedList>
        <ListEl title="Configure your experience">
          <RadioGroup value={importType} onChange={setImportType} mt={10}>
            <Flex gap={4}>
              <Box
                as="label"
                flex={1}
                p={6}
                border="1px solid"
                borderColor={importType === 'last100' ? 'blue.500' : 'gray.200'}
                borderRadius="lg"
                cursor="pointer"
                _hover={{ borderColor: 'blue.500' }}
                role="group"
                position="relative"
              >
                <Radio
                  size="lg"
                  value="scratch"
                  position="absolute"
                  top={4}
                  right={4}
                />
                <Text fontSize="xl" fontWeight="bold" mb={2}>
                  Start from scratch
                </Text>
                <Text color="gray.600">
                  Begin documenting new knowledge with up to 100 saved answers
                </Text>
              </Box>

              <Box
                as="label"
                flex={1}
                p={6}
                border="1px solid"
                borderColor={
                  importType === 'existing' ? 'blue.500' : 'gray.200'
                }
                borderRadius="lg"
                cursor="pointer"
                _hover={{ borderColor: 'blue.500' }}
                role="group"
                position="relative"
              >
                <Radio
                  size="lg"
                  value="existing"
                  position="absolute"
                  top={4}
                  right={4}
                />
                <Text fontSize="xl" fontWeight="bold" mb={2}>
                  Use existing data
                </Text>
                <Text color="gray.600">
                  Import channel history and connect your existing documentation
                </Text>
              </Box>
            </Flex>
          </RadioGroup>
        </ListEl>

        <ListEl
          mt={8}
          title="What language do you want your documentation to be in?"
          opacity={importType ? 1 : 0.3}
        >
          <Select
            size="md"
            w="auto"
            maxW="328px"
            mt={6}
            value={language}
            onChange={(e) => {
              setLanguage(e.target.value);
            }}
          >
            {LANGUAGES.map((l) => (
              <option key={l} value={l}>
                {l}
              </option>
            ))}
          </Select>
        </ListEl>
      </OrderedList>
      <Button
        mt={4}
        colorScheme="action"
        ml={4}
        isDisabled={!importType}
        onClick={submit.submit}
        isLoading={submit.isSubmitting}
      >
        Next
      </Button>
    </>
  );
}
