import { ImCheckmark } from 'react-icons/im';

import { Box, Flex, Icon, Text } from '@chakra-ui/react';

import { AppOnboardingInstall } from 'src/App/App.OnboardingInstall';
import { AppOnboardingPreconfigure } from 'src/App/App.OnboardingPreconfigure';
import { AppOnboardingSuccess } from 'src/App/App.OnboardingSuccess';
import { AppOnboardingSurvey } from 'src/App/App.OnboardingSurvey';
import { AppOnboardingUpgrade } from 'src/App/App.OnboardingUpgrade';
import { Logo } from 'src/shared/components/Logo';
import { useDirectoryTeam } from 'src/shared/hooks/useDirectory';

export function AppOnboarding() {
  const team = useDirectoryTeam();

  let step = 1;
  if (team.survey) {
    step = 2;
  }
  if (team.primary_language) {
    step = 3;
  }
  if (team.onboarding_added_experts) {
    step = 4;
  }

  if (team.onboarding_complete) {
    step = 5;
  }

  return (
    <>
      <Flex pl={5} h="68px" align="center" borderBottom="1px solid #E0E0E0">
        <Logo />
        <Text
          ml={12}
          fontSize="16px"
          lineHeight="16px"
          fontWeight={700}
          letterSpacing="-0.02em"
          opacity={step === 1 ? 1 : 0.6}
          position="relative"
        >
          {step > 1 && (
            <Icon
              position="absolute"
              left={-5}
              top={0.5}
              opacity={0.6}
              as={ImCheckmark}
              w={3}
              h={3}
            />
          )}
          Survey
        </Text>
        <Text
          ml={12}
          fontSize="16px"
          lineHeight="16px"
          fontWeight={700}
          letterSpacing="-0.02em"
          opacity={step === 2 ? 1 : 0.6}
        >
          {step > 2 && (
            <Icon
              position="absolute"
              left={-5}
              top={0.5}
              opacity={0.6}
              as={ImCheckmark}
              w={3}
              h={3}
            />
          )}
          Configure
        </Text>
        <Text
          ml={12}
          fontSize="16px"
          lineHeight="16px"
          fontWeight={700}
          letterSpacing="-0.02em"
          opacity={step === 3 ? 1 : 0.6}
        >
          {step > 3 && (
            <Icon
              position="absolute"
              left={-5}
              top={0.5}
              opacity={0.6}
              as={ImCheckmark}
              w={3}
              h={3}
            />
          )}
          Install
        </Text>
      </Flex>
      <Box maxW="700px" m="120px auto" height="auto" px={2} pb={24}>
        {step === 1 && <AppOnboardingSurvey />}
        {step === 2 && <AppOnboardingPreconfigure />}
        {step === 3 && <AppOnboardingInstall />}
        {step === 4 && <AppOnboardingUpgrade />}
        {step === 5 && <AppOnboardingSuccess />}
      </Box>
    </>
  );
}
