import { useState } from 'react';

import { Button, OrderedList, Select, Text } from '@chakra-ui/react';
import { OnboardingUpgradeEndpoint } from '@core/types/types.endpoint.onboarding';

import { ListEl } from 'src/App/App.ListEl';
import { SettingsIntegrations } from 'src/Settings/SettingsIntegrations';
import { useDirectoryUpdater } from 'src/shared/hooks/shared.hook.useDirectoryUpdater';
import { useSubmitter } from 'src/shared/hooks/useSubmitter';
import { callApi } from 'src/shared/util/util.callApi';

export function AppOnboardingUpgrade() {
  const updater = useDirectoryUpdater();
  const [importDays, setImportDays] = useState('30');

  const submit = useSubmitter(async () => {
    const [, res] = await callApi<OnboardingUpgradeEndpoint>(
      '/onboarding/upgrade',
      {
        days: Number(importDays),
      },
    );

    updater(res);
  });

  return (
    <>
      <OrderedList>
        <ListEl title="Convert channel history to FAQs">
          <Select
            mt={3}
            value={importDays}
            onChange={(e) => setImportDays(e.target.value)}
            maxW="240px"
          >
            <option value="0">No, thanks</option>
            <option value="30">Last 30 days</option>
            <option value="90">Last 90 days</option>
          </Select>
          <Text mt={2} fontWeight={500} color="gray.500" fontSize="xs">
            Import your channel history to create FAQs
          </Text>
        </ListEl>
        <ListEl title="Connect your existing documentation">
          <SettingsIntegrations />
          <Text mt={2} fontWeight={500} color="gray.500" fontSize="xs">
            Include search results from your favorite tools
          </Text>
        </ListEl>
        <ListEl title="Click the magic button">
          <Button
            mt={2}
            colorScheme="action"
            size="sm"
            onClick={submit.submit}
            isLoading={submit.isSubmitting}
          >
            Create Question Base
          </Button>
        </ListEl>
      </OrderedList>
    </>
  );
}
